export const GET_MEDIA_MAP = "GET_MEDIA_MAP";
export const GET_MEDIA_MAP_SUCCESS = "GET_MEDIA_MAP_SUCCESS";
export const GET_MEDIA_MAP_FAILURE = "GET_MEDIA_MAP_FAILURE";

export const UPDATE_MEDIA_MAP_URL = "UPDATE_MEDIA_MAP_URL";
export const UPDATE_MEDIA_MAP_URL_SUCCESS = "UPDATE_MEDIA_MAP_URL_SUCCESS";
export const UPDATE_MEDIA_MAP_URL_FAILURE = "UPDATE_MEDIA_MAP_URL_FAILURE";
export const UPDATE_MEDIA_MAP_URL_RESET = "UPDATE_MEDIA_MAP_URL_RESET";

const initialState = {
  loading: false,
  collections: [],
  error: null,
  url: '',
  name: '',
  domain: ''
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_MEDIA_MAP:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_MEDIA_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        collections: payload
      };
    case GET_MEDIA_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_MEDIA_MAP_URL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_MEDIA_MAP_URL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MEDIA_MAP_URL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_MEDIA_MAP_URL_RESET:
      return {
        ...state,
        saving: false,
        saveSuccess: null,
        saveError: null
      };

    default:
      return {
        ...state,
      }
  }
}
