export const GET_DOMAIN_CONFIG = "GET_DOMAIN_CONFIG";
export const GET_DOMAIN_CONFIG_SUCCESS = "GET_DOMAIN_CONFIG_SUCCESS";
export const GET_DOMAIN_CONFIG_FAILURE = "GET_DOMAIN_CONFIG_FAILURE";

export const UPDATE_DOMAIN_CONFIG = "UPDATE_DOMAIN_CONFIG";
export const UPDATE_DOMAIN_CONFIG_SUCCESS = "UPDATE_DOMAIN_CONFIG_SUCCESS";
export const UPDATE_DOMAIN_CONFIG_FAILURE = "UPDATE_DOMAIN_CONFIG_FAILURE";

const initialState = {
    loading: false,
    collections: [],
    error: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_DOMAIN_CONFIG:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_DOMAIN_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                collections: payload
            };
        case GET_DOMAIN_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_DOMAIN_CONFIG:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DOMAIN_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                collections: payload
            };
        case UPDATE_DOMAIN_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return {
                ...state,
            }
    }
}

