export const GET_EXCHANGES = "GET_EXCHANGES";
export const GET_EXCHANGES_SUCCESS = "GET_EXCHANGES_SUCCESS";
export const GET_EXCHANGES_FAILURE = "GET_EXCHANGES_FAILURE";

export const UPDATE_EXCHANGES = "UPDATE_EXCHANGES";
export const UPDATE_EXCHANGES_SUCCESS = "UPDATE_EXCHANGES_SUCCESS";
export const UPDATE_EXCHANGES_FAILURE = "UPDATE_EXCHANGES_FAILURE";

const initialState = {
    loading: false,
    collections: [],
    error: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_EXCHANGES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_EXCHANGES_SUCCESS:
            return {
                ...state,
                loading: false,
                collections: payload
            };
        case GET_EXCHANGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_EXCHANGES:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_EXCHANGES_SUCCESS:
            return {
                ...state,
                loading: false,
                collections: payload
            };
        case UPDATE_EXCHANGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return {
                ...state,
            }
    }
}
