export const GET_GLOBAL_LINKS = "GET_GLOBAL_LINKS";
export const GET_GLOBAL_LINKS_SUCCESS = "GET_GLOBAL_LINKS_SUCCESS";
export const GET_GLOBAL_LINKS_FAILURE = "GET_GLOBAL_LINKS_FAILURE";

export const UPDATE_GLOBAL_LINKS = "UPDATE_GLOBAL_LINKS";
export const UPDATE_GLOBAL_LINKS_SUCCESS = "UPDATE_GLOBAL_LINKS_SUCCESS";
export const UPDATE_GLOBAL_LINKS_FAILURE = "UPDATE_GLOBAL_LINKS_FAILURE";
export const UPDATE_GLOBAL_LINKS_RESET = "UPDATE_GLOBAL_LINKS_RESET";

export const DELETE_GLOBAL_LINK = "DELETE_GLOBAL_LINK";
export const DELETE_GLOBAL_LINK_SUCCESS = "DELETE_GLOBAL_LINK_SUCCESS";
export const DELETE_GLOBAL_LINK_FAILURE = "DELETE_GLOBAL_LINK_FAILURE";

const initialState = {
    loading: false,
    links: { data: { links: [] } },
    error: null,
    count: '',
    country: '',
    exclude_domains: '',
    link: ''
};

const globalLinksReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_GLOBAL_LINKS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_GLOBAL_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                links: payload
            };
        case GET_GLOBAL_LINKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_GLOBAL_LINKS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_GLOBAL_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                links: payload
            };
        case UPDATE_GLOBAL_LINKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_GLOBAL_LINKS_RESET:
            return {
                ...state,
                saving: false,
                saveSuccess: null,
                saveError: null
            };
        case DELETE_GLOBAL_LINK:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_GLOBAL_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                links: payload
            };
        case DELETE_GLOBAL_LINK_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default globalLinksReducer;
