import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "src/vendor/libs/react-toastify/react-toastify.scss";

const CloseButton = ({ closeToast }) => (
  <button
    className="Toastify__close-button"
    type="button"
    aria-label="close"
    onClick={closeToast}>&times;</button>
);

export const NotificationContext = React.createContext();
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const defaultOptions = {
    position: "top-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const handleShow = (message, type) => toast(message, {
    type,
    ...defaultOptions
  });

  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  return (
    <NotificationContext.Provider
      value={{
        showSuccess: (msg) => handleShow(msg, "success"),
        showError: (msg) => handleShow(msg, "error"),
        showInfo: (msg) => handleShow(msg, "info"),
        showWarning: (msg) => handleShow(msg, "warning"),
        showDefault: (msg) => handleShow(msg, "default"),
        showDark: (msg) => handleShow(msg, "dark"),
        showPrimary: (msg) => handleShow(msg, "primary"),
        showSecondary: (msg) => handleShow(msg, "secondary"),
        showAccent: (msg) => handleShow(msg, "accent"),
        showLight: (msg) => handleShow(msg, "light"),
        showTransparent: (msg) => handleShow(msg, "transparent"),
        showWhite: (msg) => handleShow(msg, "white"),
        showBlack: (msg) => handleShow(msg, "black"),
        showNeutral: (msg) => handleShow(msg, "neutral"),
        showConfirm: (msg) => handleShow(msg, "confirm"),
      }}
    >
      {children}
      <ToastContainer
        autoClose={5000}
        newestOnTop
        closeButton={<CloseButton />}
        rtl={isRTL}
      />
    </NotificationContext.Provider>
  );
};

export default useNotification;
