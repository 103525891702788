export const GET_CLIENT_MAP = "GET_CLIENT_MAP";
export const GET_CLIENT_MAP_SUCCESS = "GET_CLIENT_MAP_SUCCESS";
export const GET_CLIENT_MAP_FAILURE = "GET_CLIENT_MAP_FAILURE";

export const UPDATE_CLIENT_MAP = "UPDATE_CLIENT_MAP";
export const UPDATE_CLIENT_MAP_SUCCESS = "UPDATE_CLIENT_MAP_SUCCESS";
export const UPDATE_CLIENT_MAP_FAILURE = "UPDATE_CLIENT_MAP_FAILURE";
export const UPDATE_CLIENT_MAP_RESET = "UPDATE_CLIENT_MAP_RESET";

export const DELETE_CLIENT_MAP = "DELETE_CLIENT_MAP";
export const DELETE_CLIENT_MAP_SUCCESS = "DELETE_CLIENT_MAP_SUCCESS";
export const DELETE_CLIENT_MAP_FAILURE = "DELETE_CLIENT_MAP_FAILURE";

const initialState = {
    loading: false,
    data: [],
    error: null,
    client_name: '',
    domains: '',
};

const clientMapReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CLIENT_MAP:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_CLIENT_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case GET_CLIENT_MAP_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_CLIENT_MAP:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_CLIENT_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case UPDATE_CLIENT_MAP_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case UPDATE_CLIENT_MAP_RESET:
            return {
                ...state,
                saving: false,
                saveSuccess: null,
                saveError: null
            };
        case DELETE_CLIENT_MAP:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_CLIENT_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case DELETE_CLIENT_MAP_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default clientMapReducer;
