export const GET_BLOCKS_REPORT = "GET_BLOCKS_REPORT";
export const GET_BLOCKS_REPORT_SUCCESS = "GET_BLOCKS_REPORT_SUCCESS";
export const GET_BLOCKS_REPORT_FAILURE = "GET_BLOCKS_REPORT_FAILURE";
export const BLOCKS_REPORT_CLEAN = "BLOCKS_REPORT_CLEAN";
export const UPDATE_QUERY_STATUS = "UPDATE_QUERY_STATUS";

const initialState = {
  loading: false,
  report: null,
  error: null,
  queryState: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case BLOCKS_REPORT_CLEAN:
      return {
        ...initialState,
      };

    case GET_BLOCKS_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
        // report: null,
        queryState: null,
      };

    case GET_BLOCKS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        report: payload,
        queryState: "SUCCEEDED",
      };

    case GET_BLOCKS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        queryState: "FAILED",
      };

    case UPDATE_QUERY_STATUS:
      return {
        ...state,
        queryState: payload,
        loading: payload === "RUNNING",
      };

    default:
      return {
        ...state,
      };
  }
}
