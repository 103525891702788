import { combineReducers } from "redux";
import theme from "./themeStore";
import user from "./user";
import tags from "./tags";
import links from "./links";
import creatives from "./creatives";
import dashboard from "./dashboard";
import reports from "./reports";
import mediaMap from "./mediaMap";
import globalLinks from "./globalLinks";
import exchanges from "./exchanges";
import domainConfig from "./domainConfig";
import blocksReport from "./blocksReport";
import clientMap from "./clientMap";

const appReducer = combineReducers({
  creatives,
  dashboard,
  links,
  reports,
  tags,
  theme,
  user,
  mediaMap,
  globalLinks,
  exchanges,
  domainConfig,
  blocksReport,
  clientMap
});

export default (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action)
};
